const blue = '#041699';
const darkBlue = '#101E63';
const purple = '#3E3593';
const pink = '#E9ABC2';
const teak = '#BD7C62';
const darkGrey = '#231F20';
const green = '#ABD6A6';

export {
  blue,
  darkBlue,
  darkGrey,
  purple,
  pink,
  teak,
  green
};